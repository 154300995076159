module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dorval AM","icon":"src/images/icon.png","start_url":"/","theme_color":"#003e90","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bf877b89ba88682d656d1e211ee7de17"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["fr_FR","en_FR","fr_BE","en_BE","fr_LU","en_LU","fr_CH","en_CH","it_IT","en_ES","en_SE"],"defaultLanguage":"fr_FR","generateDefaultLanguagePage":false,"trailingSlash":"always","siteUrl":"https://dorval-site-preprod-gatsby.digital4better.com","redirect":true,"i18nextOptions":{"defaultNS":"translations","debug":false,"lowerCaseLng":false,"saveMissing":false,"interpolation":{"escapeValue":false},"nsSeparator":false},"pages":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
