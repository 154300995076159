export function disableCorePrefetching() {
  return true;
}

export function shouldUpdateScroll({ routerProps: { location } }) {
  const { hash } = location;
  if (hash === '') {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
    return false;
  }
  return true;
}
